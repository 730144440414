html,
body {
  // background-color: $light-color;
  // color: $white;
  font-family: "Roboto", sans-serif;
  height: 100%;
}

body > #root {
  height: 100vh;
}

button {
  // color: $darker-color;
  // background-color: $accent-color;
  // padding: 0.7em 1em;
  // border-radius: 0.5em;
  // margin: 1em;
  // border: none;
  // transition: 0.05s;
  // font-size: 1em;

  // &:hover {
  //   // background-color: $lighter-accent-color;
  //   transform: scale(1.05);
  // }

  // &:active {
  //   // background-color: $darker-accent-color;
  //   transform: scale(1);
  // }
}

#kofi-button {
  padding: 0;
  background-color: transparent;
  transition: 0.05s;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(1);
  }
}
